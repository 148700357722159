document.addEventListener('alpine:init', () => {
    Alpine.data('numberCounter', (baseNumber) => ({
        baseNumber: baseNumber,
        continuous: false,
        trackedNumber: 0,

        init() {
            if (this.continuous) {
                setInterval(() => {
                    if (this.$refs.odoField.classList.contains('odometer-animating')) {
                        return
                    }

                    this.trackedNumber += this.getRandomInt(1, 12)
                }, this.getRandomInt(1100, 3000))
            }
        },

        getValue() {
            return this.comma_helper(this.trackedNumber.toFixed(3).toString())
        },

        onIntersect(enter) {
            if (!this.continuous && enter) {
                this.trackedNumber = this.baseNumber
            } else if (!this.continuous && !enter) {
                this.trackedNumber = 0
            }
        },

        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min);
        },

        comma_helper(nStr) {
            nStr += ''
            var x = nStr.split('.')
            var x1 = x[0]
            // The below line doesn't appear to be used? -- Leaving here just in case
            // var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return x1
        }
    }))
})